import request from '@/utils/axios'

// 登录
export function Login(data) {
  return request({
    url: '/v1/user/session/login',
    method: 'POST',
    data
  })
}

// 注册
export function Register(data) {
  return request({
    url: '/v1/user/register',
    method: 'POST',
    data
  })
}

// 注销登录
export function Logout(params) {
  return request({
    url: '/v1/user/session/logout',
    method: 'GET',
    params
  })
}

// 修改密码
// export function ChangePassword(data) {
//   return request({
//     url: '/v1/user/password',
//     method: 'PUT',
//     data
//   })
// }

// 获取用户信息
export function GetUserInfo(params) {
  return request({
    url: '/v1/user/info',
    method: 'GET',
    params
  })
}

// 修改用户信息
export function SetUserInfo(data) {
  return request({
    url: '/v1/user/info',
    method: 'PUT',
    data
  })
}

// 取消订阅
export function Unsubscribe(data = {}) {
  return request({
    url: '/v1/user/cancel/subscription',
    method: 'PUT',
    data
  })
}

// 语音转文字
export function speechToText(data) {
  return request({
   url: '/v1/convert/speechToText2',
   method: 'post',
   data,
   custom: {
    headers: {
     website: 'recovery',
     phone_os: 2,
     apk_version_code: 10004
    }
   }
  })
}

// 获取路径
export function UploadFile(data) {
  return request({
    url: '/v1/convert/uploadFile',
    method: 'POST',
    data
  })
}

// 查询结果
export function GetResult(params) {
  return request({
    url: '/v1/convert/result',
    method: 'GET',
    params
  })
}